import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Status from "../Status/Status";
import imgPath from "../../assets/images/dataNotFound.png";
import { NO_DATA_FOUND } from "../../Constant/StaticData";
import { Button, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CompanyTables = (props) => {
  return (
    <div className="customizedTable">
      {props?.tableData?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            size="small"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Company Name</StyledTableCell>
                <StyledTableCell align="left">Country</StyledTableCell>
                <StyledTableCell align="right">View Details</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.tableData?.map((item) => (
                <StyledTableRow key={item?._id}>
                  <StyledTableCell align="left">
                    {item?.Company_Name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item?.Country}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      size="small"
                      color="primary"
                      aria-label="add to shopping cart"
                      onClick={() => props?.clickDetails(item)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Status
          imgPath={imgPath}
          heading={NO_DATA_FOUND?.heading}
          message={NO_DATA_FOUND?.message}
        />
      )}
    </div>
  );
};

export default CompanyTables;
