import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import WarningMessage from "../WarningMessage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip(props) {
  const theme = useTheme();
  const [selectedField, setSelectedField] = useState([]);
  const [isError, setIsError] = useState(false);
  const { label, data = [], onChangeCallback } = props;

  const getStyles = (name, selectedField, theme) => {
    return {
      fontWeight: selectedField.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let data = typeof value === "string" ? value.split(",") : value;
    if (data?.length <= 5) {
      setSelectedField(data);
      onChangeCallback(data);
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  return (
    <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-chip-label">{label || "Select"}</InputLabel>
        <Select
          labelId={label || "Select"}
          id={label || "Select"}
          multiple
          value={selectedField}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label || "Select"} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} style={{ fontSize: "12px" }} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {isError && <WarningMessage type="error" text=" Max 5 selected is allowed" />}
          {data &&
            data?.map((data, index) => (
              <MenuItem key={index} value={data?.data} style={getStyles(data?.data, selectedField, theme)}>
                {data?.data}
              </MenuItem>
            ))}
        </Select>
        {isError && <WarningMessage type="error" text=" Max 5 selected is allowed" />}
      </FormControl>
    </div>
  );
}
