export const customStyle = {
  buyerWrapper: {},
  header: {
    marginTop: "20px",
  },
  filterSection: {
    marginBottom: "30px",
    marginTop: "30px",
    backgroundColor: "aliceblue !important",
    borderRadius: "5px !important",
    padding: "12px",
    border: "5px solid #dceefd",
  },
  searchField: {},
  searchButton: {
    padding: "12px",
    // width: "100%!important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pagination: {
    padding: "12px",
    float: "right",
  },
};
