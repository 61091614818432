import { Grid } from "@mui/material";
import React from "react";
import ActionCard from "../ActionCard";
import PublicIcon from "@mui/icons-material/Public";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import BusinessIcon from "@mui/icons-material/Business";

import growthImg from "../../assets/images/icons/growth.png";
import country from "../../assets/images/icons/country.png";
import importer from "../../assets/images/icons/import.png";
import exporter from "../../assets/images/icons/export.png";

const DashboardReportBox = (props) => {
  let { Shipments, Country, Exporter, Importer } = props?.data;

  return (
    <>
      {Shipments && (
        <Grid container spacing={4} style={{ padding: "16px" }}>
          <Grid item xs={3}>
            <ActionCard color="#337ab7" title="Total Shipment" count={Shipments || 0} img={growthImg} />
          </Grid>
          <Grid item xs={3}>
            <ActionCard color="#d32f2f" title="Total Country" count={Country || 0} img={country} />
          </Grid>
          <Grid item xs={3}>
            <ActionCard color="#006c04" title="Total Exporter" count={Exporter || 0} img={exporter} />
          </Grid>
          <Grid item xs={3}>
            <ActionCard color="#ffcb00" title="Total Importer" count={Importer || 0} img={importer} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DashboardReportBox;
