export const importTableConstant = {
  headerKey: [
    "Date",
    "Month",
    "HS Code",
    "Product Description",
    "Port of Loading",
    "Mode of Shipment",
    "Port of Discharge/Port Code",
    "Country of Origin",
    "Exporter Name",
    "Exporter Address",
    "Quantity",
    "Unit Quantity Code",
    "Invoice Currency",
    "Invoice Unite Price in FC",
    "Unit Price USD",
    "Total Value USD",
    "Total Duty USD",
    "CHA Name",
    "IEC",
    "Importer Name",
    "Importer Address",
    "Importer City State",
    "Importer PIN",
    "Importer Phone",
    "Importer Mail",
    "Importer Contact Person 1",
    "Importer Contact Person 2",
    "Shipment ID",
    "Country of Destination",
    "BE Type",
    "AG",
    "Unit Price in INR",
    "Total Value in INR",
    "Total Duty INR",
  ],
  bodyKey: [
    "Date",
    "Month",
    "HS_Code",
    "Item_Description",
    "Port_of_Loading",
    "Mode_Of_Shipment",
    "Port_of_Discharge",
    "Country",
    "Exporter_Name",
    "Exporter_Address",
    "Quantity",
    "UQC",
    "Invoice_Currency",
    "Invoice_Unit_Price_FC",
    "Unit_Price_USD",
    "Total_Value_USD",
    "Total_Duty_USD",
    "CHA_Name",
    "IEC",
    "Importer_Name",
    "Importer_Address",
    "Importer_City_State",
    "Importer_PIN",
    "Importer_Phone",
    "Importer_Mail",
    "Importer_Contact_Person_1",
    "Importer_Contact_Person_2",
    "Shipment_ID",
    "Country_of_Destination",
    "BE_Type",
    "AG",
    "Unit_Price_in_INR",
    "Total_Value_in_INR",
    "Total_Duty_INR",
  ],
  demoHeaderKey: [
    "Date",
    "HSN Code",
    "Product Description",
    "Quantity",
    "Unit Quantity Code",
    "Country of Origin",
    "Importer Name",
    "Exporter Name",
    "View More",
  ],
  demoBodyKey: ["Date", "HS_Code", "Item_Description", "Quantity", "UQC", "Country", "Importer_Name", "Exporter_Name"],
};

export const exportTableConstant = {
  headerKey: [
    "Date",
    "Month",
    "HS Code",
    "Product Description",
    "Port of Loading/ Port Code",
    "Mode of Shipment",
    "Port of Discharge",
    "Country of Destination",
    "Importer Name",
    "Importer Address",
    "Quantity",
    "Unit Quantity Code",
    "Unit Rate in FC",
    "Currency",
    "Unit Value USD",
    "Total Value USD",
    "IEC",
    "Country of Origin",
    "Shipment ID",
    "Exporter Name",
    "Exporter Address",
    "Exporter City State",
    "Exporter PIN",
    "Exporter Phone",
    "Exporter Mail",
    "Exporter Contact Person 1",
    "Exporter Contact Person 2",
    "Unit Price in INR",
    "Total Value in INR",
  ],
  demoHeaderKey: [
    "Date",
    "HS Code",
    "Product Description",
    "Quantity",
    "Unit Quantity Code",
    "Country of Destination",
    "Importer Name",
    "Exporter Name",
    "View More",
  ],
  demoBodyKey: ["Date", "HS_Code", "Item_Description", "Quantity", "UQC", "Country", "Importer_Name", "Exporter_Name"],
  bodyKey: [
    "Date",
    "Month",
    "HS_Code",
    "Item_Description",
    "Port_of_Loading",
    "Mode_of_Shipment",
    "Port_of_Discharge",
    "Country",
    "Importer_Name",
    "Importer_Address",
    "Quantity",
    "UQC",
    "Unit_Rate_In_FC",
    "Currency",
    "Unit_Value_USD",
    "Total_Value_USD",
    "IEC",
    "Country_of_Origin",
    "Shipment_ID",
    "Exporter_Name",
    "Exporter_Address",
    "Exporter_City_State",
    "Exporter_PIN",
    "Exporter_Phone",
    "Exporter_Mail",
    "Exporter_Contact_Person_1",
    "Exporter_Contact_Person_2",
    "Unit_Price_in_INR",
    "Total_Value_in_INR",
  ],
};
