import * as React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import PlansBgImg from "../../assets/images/planPricing.jpg";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import { PageHeading, CustomParaGraph } from "../../components";
import SubHeading from "../SubHeading/SubHeading";

const Plan = (props) => {
  const navigate = useNavigate();

  return (
    <div className="plan">
      <Container maxWidth="lg">
        <Grid container spacing={6} className="plan__header">
          <Grid item xs={12}>
            <div className="plan__header__section">
              <SubHeading
                customStyle={{ textAlign: "center", color: "#337ab7" }}
                text="Plan and Pricing"
              />
              <CustomParaGraph
                variant="body1"
                customStyle={{ fontSize: "12px", textAlign: "center" }}
                text="BizDiving is an online platform created by Zcel India Llp, where one can search, create and accelerate plans and procedures based on
                import and export for their business growth. This portal is highly dedicated for the Lifescience Industries."
              />
              {/* <div className="plan__header__section__button">
                <Button variant="contained">Schedule a Demo</Button>
              </div> */}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Card sx={{ maxWidth: "100%" }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="120"
                image={PlansBgImg}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="center"
                className="plan__planName"
              >
                Basic
              </Typography>

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <b>DATA ACCESS:</b> 2 Chapters
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRANSACTION Details:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>HS CODE/ PRODUCT NAME SEARCHING:</b> Unlimited
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRADE ANALYSIS:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>CONTACT INFO:</b> 50
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>DOWNLOADING:</b> 50000
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>VALIDITY:</b> 1 Year
                </Typography>
                <hr />
              </CardContent>
              <CardActions
                className="plan__pricing"
                onClick={() => navigate("/contact-us")}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  className="plan__pricing__text"
                >
                  <b style={{ textAlign: "center" }}>Please contact to sales</b>
                  {/* <small>(Plus taxes as applicable)</small> */}
                </Typography>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Card sx={{ maxWidth: "100%" }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="120"
                image={PlansBgImg}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="center"
                className="plan__planName"
              >
                Advance
              </Typography>

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <b>DATA ACCESS:</b> 3 Chapters
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRANSACTION Details:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>HS CODE/ PRODUCT NAME SEARCHING:</b> Unlimited
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRADE ANALYSIS:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>CONTACT INFO:</b> 100
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>DOWNLOADING:</b> 75000
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>VALIDITY:</b> 1 Year
                </Typography>
                <hr />
              </CardContent>
              <CardActions
                className="plan__pricing"
                onClick={() => navigate("/contact-us")}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  className="plan__pricing__text"
                >
                  <b style={{ textAlign: "center" }}>Please contact to sales</b>
                  {/* <small>(Plus taxes as applicable)</small> */}
                </Typography>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Card sx={{ maxWidth: "100%" }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="120"
                image={PlansBgImg}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="center"
                className="plan__planName"
              >
                Deluxe
              </Typography>

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <b>DATA ACCESS:</b> 5 Chapters
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRANSACTION Details:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>HS CODE/ PRODUCT NAME SEARCHING:</b> Unlimited
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRADE ANALYSIS:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>CONTACT INFO:</b> 150
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>DOWNLOADING:</b> 100000
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>VALIDITY:</b> 1 Year
                </Typography>
                <hr />
              </CardContent>
              <CardActions
                className="plan__pricing"
                onClick={() => navigate("/contact-us")}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  className="plan__pricing__text"
                >
                  <b style={{ textAlign: "center" }}>Please contact to sales</b>
                  {/* <small>(Plus taxes as applicable)</small> */}
                </Typography>
              </CardActions>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
            <Card sx={{ maxWidth: "100%" }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="120"
                image={PlansBgImg}
              />
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="center"
                className="plan__planName"
              >
                Super Deluxe
              </Typography>

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <b>DATA ACCESS:</b> 5 Chapters
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRANSACTION Details:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>HS CODE/ PRODUCT NAME SEARCHING:</b> Unlimited
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>TRADE ANALYSIS:</b> Yes
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>CONTACT INFO:</b> 300
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>DOWNLOADING:</b> 200000
                </Typography>
                <hr />
                <Typography variant="body2" color="text.secondary">
                  <b>VALIDITY:</b> 2 Year
                </Typography>
                <hr />
              </CardContent>
              <CardActions
                className="plan__pricing"
                onClick={() => navigate("/contact-us")}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  textAlign="center"
                  className="plan__pricing__text"
                >
                  <b style={{ textAlign: "center" }}>Please contact to sales</b>
                  {/* <small>(Plus taxes as applicable)</small> */}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Plan;
