import React, { useState } from "react";
import { Button, FormControl, Grid, Input, InputLabel } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import WarningMessage from "../WarningMessage/WarningMessage";
import ApiRequest from "../../utility/APIRequest";
import API_endpoint from "../../utility/APIEndPoints";
import PageLoader from "../PageLoader";

const Signup = (props) => {
  const [errorMessage, setErrorMessage] = useState({
    status: false,
    message: "",
  });
  const [inProcess, setinProcess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data?.password === data?.conformPassword) {
      setinProcess(true);
      let apiData = {
        full_name: data?.full_name,
        email: data?.email,
        phone: data?.phone,
        company_name: data?.company_name,
        designation: data?.designation,
        address: data?.address,
        password: data?.password,
      };
      ApiRequest.request(API_endpoint?.signup, "POST", apiData).then((res) => {
        if (res?.status) {
          setinProcess(false);
          props.closeModal();
        } else {
          setinProcess(false);
          setErrorMessage({
            message: res?.errors?.[0]?.message,
            status: true,
          });
        }
      });
    } else {
      setErrorMessage({
        message: "Your password and confirm password does not match",
        status: true,
      });
    }
  };

  return (
    <div className="login">
      <PageLoader open={inProcess} />
      <Grid container className="login__div">
        <form onSubmit={handleSubmit(onSubmit)} className="login__div__form">
          <div className="login__div__form__section">
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="full_name">Full Name</InputLabel>
                <Input
                  id="full_name"
                  type="text"
                  {...register("full_name", {
                    min: 2,
                    required: true,
                  })}
                />
              </FormControl>
              {errors?.full_name && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid email"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="text"
                  {...register("email", {
                    min: 2,
                    required: true,
                    pattern:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  })}
                />
              </FormControl>
              {errors?.email && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid email"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="phone">Phone Number</InputLabel>
                <Input
                  id="phone"
                  type="text"
                  {...register("phone", {
                    min: 2,
                    required: false,
                  })}
                />
              </FormControl>
              {errors?.phone && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid email"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="company_name">Company Name</InputLabel>
                <Input
                  id="company_name"
                  type="text"
                  {...register("company_name", {
                    min: 2,
                    required: false,
                  })}
                />
              </FormControl>
              {errors?.company_name && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid company name"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="designation">Designation</InputLabel>
                <Input
                  id="designation"
                  type="text"
                  {...register("designation", {
                    min: 2,
                    required: false,
                  })}
                />
              </FormControl>
              {errors?.designation && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid designation"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="address">Address</InputLabel>
                <Input
                  id="address"
                  type="text"
                  {...register("address", {
                    min: 2,
                    required: false,
                  })}
                />
              </FormControl>
              {errors?.address && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid address"
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  type="password"
                  {...register("password", { min: 2, required: true })}
                />
              </FormControl>
              {errors?.password?.type === "required" && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid password"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="conform-password">
                  Conform Password
                </InputLabel>
                <Input
                  id="conform-password"
                  type="password"
                  {...register("conformPassword", { min: 2, required: true })}
                />
              </FormControl>
              {errors?.conformPassword?.type === "required" && (
                <WarningMessage type="error" text="Password is not match" />
              )}
            </Grid>
            {errorMessage?.status && (
              <WarningMessage type="error" text={errorMessage.message} />
            )}

            <Grid item xs={12} className="login__div__form__submit">
              <Button variant="contained" type="submit" endIcon={<SendIcon />}>
                Sign Up
              </Button>
              <p className="login__div__form__create">
                Already have account?{" "}
                <span onClick={props.externalLink}>Login</span>
              </p>
            </Grid>
          </div>
        </form>
      </Grid>
    </div>
  );
};
export default Signup;
