import React, { useState } from "react";
import { Button, FormControl, Grid, Input, InputLabel } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useForm } from "react-hook-form";
import WarningMessage from "../WarningMessage/WarningMessage";
import ApiRequest from "../../utility/APIRequest";
import PageLoader from "../PageLoader";
import API_endpoint from "../../utility/APIEndPoints";

const Login = (props) => {
  const [inProcess, setinProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    status: false,
    message: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setinProcess(true);
    ApiRequest.request(API_endpoint?.login, "POST", data).then((res) => {
      if (res?.status) {
        setinProcess(false);
        localStorage.setItem("token", res?.result?.token);
        props.isUserLogedin();
        window.location.reload();
      } else {
        setinProcess(false);
        setErrorMessage({
          message: res?.errors?.[0]?.message,
          status: true,
        });
      }
    });
  };

  return (
    <div className="login">
      <PageLoader open={inProcess} />
      <Grid container className="login__div">
        <form onSubmit={handleSubmit(onSubmit)} className="login__div__form">
          <div className="login__div__form__section">
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="text"
                  {...register("email", {
                    min: 2,
                    required: true,
                    pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  })}
                />
              </FormControl>
              {errors?.email && <WarningMessage type="error" text="Please provide the valid email" />}
            </Grid>

            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input id="password" type="password" {...register("password", { min: 2, required: true })} />
              </FormControl>
              {errors?.password?.type === "required" && <WarningMessage type="error" text="Please provide the valid password" />}
              {errorMessage?.status && <WarningMessage type="error" text={errorMessage.message} />}
            </Grid>
            <WarningMessage type="error" text={""} />

            <Grid item xs={12} className="login__div__form__submit">
              <p className="login__div__form__forgot" onClick={props.isForgot}>
                Forgot Password
              </p>
              <Button variant="contained" type="submit" endIcon={<SendIcon />}>
                Login
              </Button>
            </Grid>
          </div>
        </form>
      </Grid>
    </div>
  );
};
export default Login;
