export const promotions = {
  mainDiv: {
    color: "#fff",
  },
  subDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "50px",
  },
  section: {
    img: {
      width: "100%",
      marginTop: "25px",
      marginBottom: "25px",
      borderRadius: "10px",
    },
    promotionButton: {
      marginTop: "30px",
    },
    contentDiv: {
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
    },
  },
};
