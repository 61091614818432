import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
// import SwipeableViews from "react-swipeable-views";
// import { autoPlay } from "react-swipeable-views-utils";
// import { Padding } from "@mui/icons-material";

import sliderImg1 from "../../assets/images/Slider/1.png";
import sliderImg2 from "../../assets/images/Slider/2.png";
import sliderImg3 from "../../assets/images/Slider/3.png";

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Life Science industry growth with global market research report 1",
    message:
      "BizDiving is the most powerful analysis tool for global manufacturers or exporters or importer",
    imgPath: sliderImg1,
  },
  // {
  //   label: "Lifescience industry growth with global market research report 2",
  //   message:
  //     "BizDiving is the most powerful analysis tool for global manufacturers or exporters or importer",
  //   imgPath: sliderImg2,
  // },
  // {
  //   label: "Lifescience industry growth with global market research report 3",
  //   message:
  //     "BizDiving is the most powerful analysis tool for global manufacturers or exporters or importer",
  //   imgPath: sliderImg3,
  // },
];

const customStyle = {
  text: {
    position: "absolute",
    background: "#00000061",
    top: "150px",
    left: "0px",
    zIndex: 9,
    color: "#fff",
    width: "100%",
    padding: "20px",
  },
  textHeader: {
    color: "#ddd",
    textAlign: "center",
    fontSize: "28px",
  },
  textPara: {
    color: "#ddd",
    textAlign: "center",
    fontSize: "18px",
  },
};

const Carousel = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
      {/* <div style={customStyle.text}>
        <h2 style={customStyle.textHeader}>{images[activeStep].label}</h2>
        <Typography variant="body1" gutterBottom textAlign="center" style={customStyle.textPara}>
          {images[activeStep].message}
        </Typography>
      </div> */}
      {/* <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      > */}
      {images.map((step, index) => (
        <div key={step.label}>
          {Math.abs(activeStep - index) <= 2 ? (
            <Box
              component="img"
              sx={{
                height: 500,
                display: "block",
                maxWidth: "100%",
                overflow: "hidden",
                width: "100%",
              }}
              src={step.imgPath}
              alt={step.label}
            />
          ) : null}
        </div>
      ))}
      {/* </AutoPlaySwipeableViews> */}
    </Box>
  );
};

export default Carousel;
