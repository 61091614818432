export const customStyle = {
  mainContainer: {
    paddingTop: "100px",
    paddingBottom: "100px",
    // backgroundColor: "aliceblue",
  },
  testimonial: {
    padding: "50px 0px",
    paddingTop: "150px",
  },
  profilePic: {
    textAlign: "center",
    marginBottom: "-50px",
  },
  data: {
    padding: "20px",
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
