const API_endpoint = {
  buyer: "/analytics/buyer/search",
  supplier: "/analytics/supplier/search",
  import: "/analytics/import/search",
  export: "/analytics/export/search",
  contact: "/contact",
  login: "/user/auth/login",
  signup: "/user/admin/createcustomer",
  forgotPassword: "/user/resetpassword",
  resetPassword: "/user/updatepassword",
  getProfile: "/user",
  userUpdate: "/user/updatecustomer",
};

export default API_endpoint;
