import React from "react";
import Typography from "@mui/material/Typography";

const CustomParaGraph = (props) => {
  return (
    <Typography
      variant="body2"
      className="custom-paragraph"
      style={props?.customStyle}
      gutterBottom
    >
      <b>{props?.boldText}</b>
      {props?.text}
    </Typography>
  );
};

export default CustomParaGraph;
