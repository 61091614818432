import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import loaderGif from "../../assets/images/loader.gif";
import "./loaderstyle.css";

const Loader = (props) => {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props?.open} style={props?.customStyle}>
        <CircularProgress color="inherit" />
        {/* <img src={loaderGif} alt="loader" /> */}
        {/* <div className="spinner"></div> */}
      </Backdrop>
    </div>
  );
};

export default Loader;
