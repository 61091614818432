import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Status from "../Status/Status";
import imgPath from "../../assets/images/dataNotFound.png";
import { NO_DATA_FOUND, URL } from "../../Constant/StaticData";
import { importTableConstant, exportTableConstant } from "../../Pages/ImportExport/TableConstant";
import { convertDateFormat } from "../../Utils/commonFunction";
import { IconButton } from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { useNavigate } from "react-router-dom";
import PromotionalPopup from "../PromotionalPopup";
import CustomizedDialogs from "../popup/Popup";
import PromotionsBanner from "../PromotionsBanner";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    // whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomizedTables = (props) => {
  const navigate = useNavigate();
  const [isShowDemo, setIsShowDemo] = useState(false);
  const { tableData, filterType, isSubscription } = props;
  const [tableHeaderKey, setTableHeaderKey] = useState([]);
  const [tableBodyKey, setTableBodyKey] = useState([]);

  useEffect(() => {
    if (filterType === "import") {
      if (isSubscription) {
        setTableHeaderKey(importTableConstant?.headerKey);
        setTableBodyKey(importTableConstant?.bodyKey);
      } else {
        setTableHeaderKey(importTableConstant?.demoHeaderKey);
        setTableBodyKey(importTableConstant?.demoBodyKey);
      }
    } else {
      if (isSubscription) {
        setTableHeaderKey(exportTableConstant?.headerKey);
        setTableBodyKey(exportTableConstant?.bodyKey);
      } else {
        setTableHeaderKey(exportTableConstant?.demoHeaderKey);
        setTableBodyKey(exportTableConstant?.demoBodyKey);
      }
    }
  }, [tableData]);

  return (
    <div className="customizedTable">
      {tableData?.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table" padding="6px" style={{ fontSize: "12px!important" }}>
            <TableHead>
              <TableRow>
                {tableHeaderKey?.map((item) => (
                  <StyledTableCell align="left">{item}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isSubscription
                ? tableData?.map((item) => (
                    <StyledTableRow key={item?.id}>
                      {tableBodyKey?.map((data, index) => (
                        <StyledTableCell
                          align="left"
                          style={
                            data === "Item_Description" ||
                            data === "Importer_Address" ||
                            data === "Exporter_Address" ||
                            data === "Supplier_Address" ||
                            data === "Buyer_Address"
                              ? { minWidth: "300px", maxWidth: "300px" }
                              : { whiteSpace: "nowrap" }
                          }
                          key={index}
                        >
                          {data === "Date" ? convertDateFormat(item[data]) : item[data]}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))
                : tableData?.map((item) => (
                    <StyledTableRow key={item?.id}>
                      {tableBodyKey?.map((data, index) => (
                        <StyledTableCell align="left" key={index}>
                          {data === "Date" ? convertDateFormat(item[data]) : item[data]}
                        </StyledTableCell>
                      ))}
                      <StyledTableCell align="left">
                        <IconButton
                          color="primary"
                          aria-label="add to shopping cart"
                          onClick={() => window.open(`${URL?.BASE_URL}trade-analysis-table`, "_blank")}
                        >
                          <KeyboardDoubleArrowRightIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Status imgPath={imgPath} heading={NO_DATA_FOUND?.heading} message={NO_DATA_FOUND?.message} />
      )}
      <CustomizedDialogs width="sm" isOpen={isShowDemo}>
        <PromotionsBanner
          heading="You don't have subscription!"
          text="Please connect with us on +91-9821342553 or info@zcelindia.com to get or renew your subscription. You can also click on the button to message our support and will call you shortly."
          onOkayClick={() => navigate("/contact-us")}
          onCancelClick={() => setIsShowDemo(false)}
        />
      </CustomizedDialogs>
    </div>
  );
};

export default CustomizedTables;
