import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, Button, CardActionArea, CardActions } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";

export default function ActionCard(props) {
  return (
    <Card sx={{ maxWidth: 345, backgroundColor: "#cce7ff" }}>
      <CardActionArea>
        {/* <CardMedia component="img" height="140" image="/static/images/cards/contemplative-reptile.jpg" alt="green iguana" /> */}
        {/* <Avatar sx={{ bgcolor: "transparent", float: "right", margin: "15px", color: "#fff", fontSize: "80px" }}>{props?.icon}</Avatar> */}

        <img src={props?.img} alt="icon" style={{ width: "50px", float: "right", padding: "20px" }} />

        <CardContent>
          <Typography variant="body2" color="#337ab7">
            {props?.title}
          </Typography>
          <Typography gutterBottom variant="h4" component="div" color="#337ab7">
            {props?.count}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
