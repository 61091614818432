import React from "react";
import CustomParaGraph from "../CustomParaGraph";
import { Grid, Typography } from "@mui/material";

const GridList = (props) => {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="GirdList">
      <div className="GirdList__section">
        <img src={props?.img} alt="images" className="GirdList__section__img" />
        <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
          <b>{props?.heading}</b>
        </Typography>
        <CustomParaGraph
          customStyle={{ textAlign: "center" }}
          text={props?.text}
        />
      </div>
    </Grid>
  );
};
export default GridList;
