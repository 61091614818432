import React from "react";
import Typography from "@mui/material/Typography";

const PageHeading = (props) => {
  return (
    <Typography
      variant="h5"
      component="div"
      className="page-heading "
      gutterBottom
    >
      {props?.text}
    </Typography>
  );
};

export default PageHeading;
