import React, { useState } from "react";
import { Button, FormControl, Grid, Input, InputLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import WarningMessage from "../WarningMessage/WarningMessage";
import ApiRequest from "../../utility/APIRequest";
import API_endpoint from "../../utility/APIEndPoints";
import PageLoader from "../PageLoader";
import { CustomAlert } from "../../Utils";

const Forgot = (props) => {
  const [ErrorMessage, setErrorMessage] = useState({
    status: false,
    message: "",
  });
  const [inProcess, setinProcess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setinProcess(true);
    ApiRequest.request(API_endpoint?.forgotPassword, "POST", data).then(
      (res) => {
        if (res?.status) {
          setinProcess(false);
          props.closeModal();
          CustomAlert(
            "Successfully sent!",
            "We have the link on your registered email to reset your password.",
            "success",
            3000
          );
        } else {
          setinProcess(false);
          setErrorMessage({
            message: res?.errors?.[0]?.message,
            status: true,
          });
        }
      }
    );
  };

  return (
    <div className="login">
      <PageLoader open={inProcess} />
      <Grid container className="login__div">
        <form onSubmit={handleSubmit(onSubmit)} className="login__div__form">
          <div className="login__div__form__section">
            <Grid item xs={12}>
              <FormControl sx={{ m: 1, width: "100%" }} variant="standard">
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="text"
                  {...register("email", {
                    min: 2,
                    required: true,
                    pattern:
                      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                  })}
                />
              </FormControl>
              {errors?.email && (
                <WarningMessage
                  type="error"
                  text="Please provide the valid email"
                />
              )}
              {ErrorMessage?.status && (
                <WarningMessage type="error" text={ErrorMessage?.message} />
              )}
            </Grid>

            <Grid item xs={12} className="login__div__form__submit">
              <Button variant="contained" type="submit" endIcon={<SendIcon />}>
                Submit
              </Button>
              <p className="login__div__form__create">
                Back to login? <span onClick={props.isLogin}>Login</span>
              </p>
            </Grid>
          </div>
        </form>
      </Grid>
    </div>
  );
};
export default Forgot;
