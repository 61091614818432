import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Status from "../Status/Status";
import imgPath from "../../assets/images/dataNotFound.png";
import { NO_DATA_FOUND } from "../../Constant/StaticData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#4a4a4a",
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 600,
    // whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    // whiteSpace: "nowrap",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomList = (props) => {
  return (
    <div>
      {props?.data?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table
              // sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
              padding="6px"
              style={{ fontSize: "12px!important" }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    {props?.leftTitle}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props?.rightTitle}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} style={{ height: "300px" }}>
            <Table
              // sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
              padding="6px"
              style={{ fontSize: "12px!important" }}
            >
              <TableBody>
                {props?.data?.map((item) => (
                  <StyledTableRow key={item?.id}>
                    <StyledTableCell align="left">{item?.data}</StyledTableCell>
                    <StyledTableCell align="right">
                      {props?.valueKey
                        ? parseFloat(item?.count).toFixed(2)
                        : item?.count}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Status
          imgPath={imgPath}
          heading={NO_DATA_FOUND?.heading}
          message={NO_DATA_FOUND?.message}
        />
      )}
    </div>
  );
};

export default CustomList;
