import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  CustomParaGraph,
  Footer,
  Header,
  PageHeading,
  PageLoader,
} from "../../components";
import ProfileImg from "../../assets/images/Profile/profile.png";
import WarningMessage from "../../components/WarningMessage/WarningMessage";
import { useForm } from "react-hook-form";
import { Message, PROFILE_CONSTANT } from "../../Constant/StaticData";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ApiRequest from "../../utility/APIRequest";
import API_endpoint from "../../utility/APIEndPoints";
import { convertDateFormat } from "../../Utils/commonFunction";

const Profile = (props) => {
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [userData, setUserData] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState("");
  const [inProcess, setInProcess] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsEditMode(false);
    handleFormData(data);
  };

  const handleFormData = (formData) => {
    let APIData = formData?.password
      ? formData
      : {
          address: formData?.address,
          company_name: formData?.company_name,
          designation: formData?.designation,
          full_name: formData?.full_name,
          phone: formData?.phone,
        };
    ApiRequest.request(API_endpoint?.userUpdate, "PATCH", APIData).then(
      (res) => {
        if (res.status) {
          setUserData(res?.data);
        }
      }
    );
  };

  const getProfileDetails = () => {
    let isLogin = localStorage.getItem("token");
    if (isLogin) {
      setInProcess(true);
      ApiRequest.request(API_endpoint?.getProfile, "GET").then((res) => {
        setUserData(res?.result?.customer);
        setSubscriptionDetails(res?.result?.customer);
        setInProcess(false);
      });
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  return inProcess ? (
    <PageLoader />
  ) : (
    <>
      <Header />
      <div className="profile">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            style={{ padding: "50px", paddingTop: "20px" }}
          >
            <Grid item xs={12}>
              <PageHeading text={PROFILE_CONSTANT?.PAGE_TITLE} />
              <CustomParaGraph text={PROFILE_CONSTANT?.PAGE_DISCRIPTION} />
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Card>
            <div className="profile__card">
              <Grid container spacing={1}>
                {/* <Grid item xs={3}> */}

                {/* </Grid> */}
                {/* <Grid item xs={9}> */}
                <div className="profile__card__rightSide">
                  {!isEditMode && (
                    <IconButton
                      color="primary"
                      aria-label="Edit profile"
                      className="profile__card__rightSide__edit"
                      onClick={() => {
                        setIsEditMode(true);
                      }}
                    >
                      <ModeEditIcon />
                    </IconButton>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                      container
                      spacing={1}
                      className="import-export__filter__filterCard__form__mainGrid"
                    >
                      <Grid item xs={12}>
                        <div className="profile__card__leftSide">
                          <div className="profile__card__leftSide__profileImage">
                            <img src={ProfileImg} alt="profile" />
                          </div>
                        </div>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{
                            padding: "5px",
                            textAlign: "center",
                            // color: "#fff",
                            marginBottom: "25px",
                            // width: "100%",
                          }}
                        >
                          {userData?.full_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{
                            padding: "5px",
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "#fff",
                            // marginTop: "25px",
                          }}
                        >
                          Presonal Details
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        {/* <h2>{!isNaN(user) ? "keshav" : "Kundan"}</h2> */}
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="full_name">Full Name</InputLabel>
                          <Input
                            id="full_name"
                            type="text"
                            {...register("full_name", {
                              min: 2,
                              required: true,
                            })}
                            readOnly={!isEditMode}
                            defaultValue={userData?.full_name || "N/A"}
                          />
                        </FormControl>
                        {errors?.full_name?.type === "required" && (
                          <WarningMessage
                            type="error"
                            text={Message.FIRSTNAME_REQUIRED}
                          />
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="email">Work Email</InputLabel>
                          <Input
                            id="email"
                            type="text"
                            readOnly={true}
                            defaultValue={userData?.email || "N/A"}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="phone">Phone Number</InputLabel>
                          <Input
                            id="phone"
                            type="text"
                            {...register("phone", {
                              min: 2,
                              required: true,
                              pattern: /^(0|[+91]{3})?[7-9][0-9]{9}$/,
                            })}
                            readOnly={!isEditMode}
                            defaultValue={userData?.phone || "N/A"}
                          />
                        </FormControl>
                        {errors?.phone && (
                          <WarningMessage
                            type="error"
                            text={Message.MOBILE_PATTERN}
                          />
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="company_name">
                            Company Name
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type="text"
                            {...register("company_name", {
                              min: 2,
                              required: true,
                            })}
                            readOnly={!isEditMode}
                            defaultValue={userData?.company_name || "N/A"}
                          />
                        </FormControl>
                        {errors?.company_name?.type === "required" && (
                          <WarningMessage
                            type="error"
                            text={Message.COMPANY_NAME_REQUIRED}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="address">
                            Company Address
                          </InputLabel>
                          <Input
                            id="address"
                            type="text"
                            {...register("address", {
                              min: 2,
                              required: true,
                            })}
                            readOnly={!isEditMode}
                            defaultValue={userData?.address || "N/A"}
                          />
                        </FormControl>
                        {errors?.address?.type === "required" && (
                          <WarningMessage
                            type="error"
                            text={Message.COMPANY_ADDRESS_REQUIRED}
                          />
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="designation">
                            Designation
                          </InputLabel>
                          <Input
                            id="designation"
                            type="text"
                            {...register("designation", {
                              min: 2,
                              required: true,
                            })}
                            readOnly={!isEditMode}
                            defaultValue={userData?.designation || "N/A"}
                          />
                        </FormControl>
                        {errors?.designation?.type === "required" && (
                          <WarningMessage
                            type="error"
                            text={Message.COMPANY_ADDRESS_REQUIRED}
                          />
                        )}
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <Input
                            id="password"
                            type="password"
                            {...register("password", {
                              min: 2,
                            })}
                            readOnly={!isEditMode}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{
                            padding: "5px",
                            textAlign: "center",
                            backgroundColor: "#1976d2",
                            color: "#fff",
                          }}
                        >
                          Subscription Details
                        </Typography>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Import HSN Code</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={subscriptionDetails?.hsn_codes || "N/A"}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Import Download limit</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.download_import_sub || "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Import Validity</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.hsn_codes_valid_upto
                                ? convertDateFormat(
                                    subscriptionDetails?.hsn_codes_valid_upto
                                  )
                                : "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Export HSN Code</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.export_hsn_codes || "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Export Download limit</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.download_export_sub || "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Export Validity</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.export_hsn_codes_valid_upto
                                ? convertDateFormat(
                                    subscriptionDetails?.export_hsn_codes_valid_upto
                                  )
                                : "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Buyer Subscription</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.buyer_sub > 0 ? "Yes" : "No"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Buyer Download Limit</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={subscriptionDetails?.buyer_sub || "0"}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Buyer Validity</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.buyer_sub_valid_upto
                                ? convertDateFormat(
                                    subscriptionDetails?.buyer_sub_valid_upto
                                  )
                                : "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Supplier Subscription</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.supplier_sub > 0
                                ? "Yes"
                                : "No"
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Supplier Download Limit</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={subscriptionDetails?.supplier_sub || "0"}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: "80%" }}
                          variant="standard"
                        >
                          <InputLabel>Supplier Validity</InputLabel>
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              subscriptionDetails?.supplier_sub_valid_upto
                                ? convertDateFormat(
                                    subscriptionDetails?.supplier_sub_valid_upto
                                  )
                                : "N/A"
                            }
                          />
                        </FormControl>
                      </Grid>

                      {isEditMode && (
                        <Grid
                          item
                          xs={12}
                          className="contactus__content__formSection__submit"
                        >
                          <Button variant="contained" type="submit">
                            Save
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </form>
                </div>
                {/* </Grid> */}
              </Grid>
            </div>
          </Card>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
