import React from "react";
import Typography from "@mui/material/Typography";

const SubHeading = (props) => {
  return (
    <Typography
      variant="h5"
      component="div"
      className="sub-heading"
      gutterBottom
      style={props?.customStyle}
    >
      {props.text}
    </Typography>
  );
};

export default SubHeading;
