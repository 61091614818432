import axios from "axios";
import { CustomAlert, handleLogout } from "../Utils";

const instance = axios.create({
  baseURL: "https://backend.bizdiving.com",
  // baseURL: "http://localhost:8080/",
});

const ApiRequest = {
  request: async function (url, method, data, parses) {
    let response = null;
    const token = localStorage.getItem("token");
    if (token) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    } else {
      delete instance.defaults.headers.common["Authorization"];
    }
    try {
      const apiResponse = await instance(url, {
        method,
        data,
        timeout: 5000000,
      });

      response = apiResponse?.data;
    } catch (error) {
      if (error?.response?.status === 400) {
        response = error?.response?.data;
      } else if (error?.response?.status === 401) {
        handleLogout();
      } else {
        response = error?.response;
      }
    }
    return response;
  },
};

export default ApiRequest;
