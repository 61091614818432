import React from "react";
import { CustomParaGraph, Footer, Header } from "../../components";
import { Status } from "../../components";
import {
  SOMETHING_WENT_WRONG_CONSTANT,
  CONTACT_DETAILS,
} from "../../Constant/StaticData";

const SomthingWentWrong = () => {
  return (
    <div className="somthingWentWrong">
      <Header />
      <div className="somthingWentWrong__section">
        <Status
          heading={SOMETHING_WENT_WRONG_CONSTANT?.TITLE}
          message={SOMETHING_WENT_WRONG_CONSTANT?.MESSAGE}
        >
          <CustomParaGraph
            boldText={`${CONTACT_DETAILS?.SUPPORT_EMAIL} or ${CONTACT_DETAILS?.SUPPORT_MOBILE}`}
            customStyle={{
              fontSize: "12px",
              textAlign: "center",
            }}
          />
          <br />
          <CustomParaGraph
            text={SOMETHING_WENT_WRONG_CONSTANT?.ANOTHER_MESSAGE}
            customStyle={{ fontSize: "12px", textAlign: "center" }}
          />
        </Status>
      </div>
      <Footer />
    </div>
  );
};

export default SomthingWentWrong;
