import React, { useState } from "react";
import { useForm } from "react-hook-form";

import {
  Grid,
  Container,
  Card,
  Input,
  InputLabel,
  FormControl,
  Button,
  Pagination,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { customStyle } from "./styles";
import { TableKey } from "./TableKey";
import { BUYER_CONSTANT, Message } from "../../Constant/StaticData";
import {
  CompanyDetails,
  CompanyTables,
  // GeneratePDF,
  PageHeading,
} from "../../components";
import {
  Footer,
  Header,
  CustomizedTables,
  CustomParaGraph,
  Promotions,
  WarningMessage,
} from "../../components";
import ApiRequest from "../../utility/APIRequest";
import CustomizedDialogs from "../../components/popup/Popup";
import * as XLSX from "xlsx";
import { CustomAlert } from "../../Utils";

const GeneratePDF = (data, fileName) => {
  let pdfData = [
    {
      "Company Name": data?.Company_Name || "N/A",
      "About Company ": data?.About_Us || "N/A",
      "Contact Person": data?.Contact_Person || "N/A",
      Designation: data?.Designation || "N/A",
      "Email Id": data?.Email_Id || "N/A",
      "Contact Number": data?.Contact_Number || "N/A",
      Address: data?.Address || "N/A",
      Country: data?.Country || "N/A",
      Platform: data?.Platform || "N/A",
      Certification: data?.Certification || "N/A",
    },
  ];

  const worksheet = XLSX.utils.json_to_sheet(pdfData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, "buyer_details.xlsx");
};

const Buyer = () => {
  const [fromData, setFormData] = React.useState([]);
  const [tableData, setTableData] = useState({
    status: false,
    data: [],
    totalPages: 0,
  });
  const [details, setDetails] = useState({
    status: false,
    data: {},
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormData(data);
    getFilterData(data, 1);
  };

  const getFilterData = (formData, pageNo) => {
    const filterAPIData = {
      search: formData?.companyName,
      page_index: pageNo > 1 ? pageNo : 1,
      page_size: 25,
    };
    ApiRequest.request("analytics/buyer/search", "POST", filterAPIData).then(
      (res) => {
        if (res?.status) {
          setTableData({
            ...tableData,
            data: res?.result?.searchResult,
            totalPages: res?.result?.pagination?.total_pages,
            status: true,
          });
        }
      }
    );
  };

  const handleViewDetails = (data) => {
    ApiRequest.request(`/analytics/buyer/search/${data?._id}`, "POST", "").then(
      (res) => {
        if (res?.statusCode === 200) {
          setDetails({ ...details, status: true, data: res?.result });

          GeneratePDF(res?.result, "buyer_details");
        } else {
          CustomAlert(
            "Subscription Expired!",
            "We don't have related subscription to access the details",
            "warning",
            2000
          );
        }
      }
    );
  };

  return (
    <div style={customStyle.buyerWrapper}>
      <Header />
      <Container maxWidth="xl">
        <Grid container spacing={2} style={customStyle?.header}>
          <Grid item xs={12}>
            <PageHeading text={BUYER_CONSTANT?.page_heading} />
            <CustomParaGraph text={BUYER_CONSTANT?.Page_message} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Card style={customStyle.filterSection}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  style={customStyle.searchField}
                  sx={{ m: 1, minWidth: "100%" }}
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    Company Name
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type="text"
                    {...register("companyName", { min: 2, required: true })}
                  />
                </FormControl>
                {errors?.companyName?.type === "required" && (
                  <WarningMessage
                    type="error"
                    text={Message.COMPANY_NAME_REQUIRED}
                  />
                )}
              </Grid>
              <Grid item xs={12} style={customStyle.searchButton}>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
        <Card>
          {tableData?.status ? (
            <div className="import-export__filter__tableCard__data">
              <CompanyTables
                tableData={tableData?.data}
                clickDetails={(data) => handleViewDetails(data)}
              />
              {tableData?.data?.length > 0 && (
                <div className="import-export__filter__tableCard__data__pagination">
                  <Stack spacing={2} style={customStyle.pagination}>
                    <Pagination
                      count={tableData?.totalPages}
                      color="primary"
                      onChange={(event, page) =>
                        getFilterData(fromData, page - 1)
                      }
                    />
                  </Stack>
                </div>
              )}
            </div>
          ) : (
            <Promotions />
          )}
        </Card>
      </Container>
      <Footer />
      <CustomizedDialogs
        width="md"
        isOpen={details?.status}
        title="Company Details"
        onClose={() => {
          setDetails({ ...details, status: false });
        }}
      >
        <CompanyDetails data={details?.data} />
      </CustomizedDialogs>
    </div>
  );
};
export default Buyer;
