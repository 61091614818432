import React from "react";
import loaderGif from "../../assets/images/loader.gif";

const Loader = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <img src={loaderGif} alt="Loading" style={{ width: "100px" }} />
      <p style={{ textAlign: "center", color: "#337ab7" }}>Loading...</p>
    </div>
  );
};
export default Loader;
