import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { CustomParaGraph, GridList } from "../../components";
import SubHeading from "../SubHeading/SubHeading";
import aboutUpperImg from "../../assets/images/About/about1.gif";
import gridImg1 from "../../assets/images/About/gridImg1.png";
import gridImg2 from "../../assets/images/About/gridImg2.png";
import gridImg3 from "../../assets/images/About/gridImg3.png";
import gridImg4 from "../../assets/images/About/gridImg4.png";
import gridImg5 from "../../assets/images/About/gridImg5.png";
import gridImg6 from "../../assets/images/About/gridImg6.png";

import { customStyle } from "./styles";

const About = () => {
  return (
    <div style={customStyle.about}>
      <Container maxWidth="lg">
        <Grid container spacing={2} style={customStyle.UpperSection}>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            style={customStyle.UpperSection.Content}
          >
            <SubHeading color="#337ab7" text="Who is Bizdiving?" />
            <CustomParaGraph
              text="BizDiving is an online platform created by Zcel India Llp, where one can search, 
                create and accelerate plans and procedures based on import and export for their business growth. 
                This portal is highly dedicated for the Life Science Industries."
            />
            <CustomParaGraph
              text="BizDiving is the most powerful analysis tool for global manufacturers or exporters 
                or importer to grab the information and market intelligence of their competitive products."
            />

            <CustomParaGraph
              text="It ensures in strategic decision-making and to expand the business growth on 
            an international basis with the help of it’s multiple features like knowing the best players in the market, 
            Main Product, Depth analysis about import and export, basic information of molecule/ products, Innovator, 
            Developer, Products application in different industries and many more information. BizDiving Database gives 
            a keen insight into international trade and helps the company with it’s own specific needs. Our aim is to 
            develop a single platform where buyers, sellers or manufacturers can connect with each other for business growth."
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            style={customStyle.UpperSection.imgDiv}
          >
            <img
              src={aboutUpperImg}
              alt="About side images"
              style={customStyle.UpperSection.imgDiv.img}
            />
          </Grid>
        </Grid>
        <Grid container spacing={10} style={customStyle.LowerSection}>
          <Grid item sm="12">
            <SubHeading
              customStyle={{
                color: "#337ab7",
                textAlign: "center",
              }}
              text="Why Bizdiving?"
            />
            <CustomParaGraph
              text="We are very much committed to our work and words. And we keep innovating day by day."
              customStyle={{ textAlign: "center", marginBottom: "50px" }}
            />
          </Grid>
          <GridList
            img={gridImg1}
            heading="Trade Explorer"
            text="Track your product's global performance with our innovative tool, monitor market competition."
          />
          <GridList
            img={gridImg2}
            heading="Life Science Insights"
            text="Comprehensive analysis: APIs, Pharmaceuticals, Chemicals, Nutra, and medical devices platform."
          />
          <GridList
            img={gridImg3}
            heading="Easily Accessible"
            text="Responsive support for business growth, overcoming import-export obstacles and challenges."
          />
          <GridList
            img={gridImg4}
            heading="Customizable Plans"
            text="We offer different plans and subscription levels to meet your specific needs and business exporting- importing challenges."
          />
          <GridList
            img={gridImg5}
            heading="Quick Response on query"
            text="Responsive support, overcome obstacles, and boost import-export business growth efficiently."
          />
          <GridList
            img={gridImg6}
            heading="Innovation & Commitment"
            text="We are very much committed to our work and words. And we keep innovating day by day."
          />
        </Grid>
      </Container>
    </div>
  );
};
export default About;
