import { Avatar, Container, Grid, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomParaGraph, Loader } from "..";
import ProfileImg from "../../assets/images/companyDetails/companyLogo.png";
import ApiRequest from "../../utility/APIRequest";
import loderGif from "../../assets/images/loader.gif";

const CompanyDetails = (props) => {
  const [status, setStatus] = useState(true);

  const customStyle = {
    paragraph: {
      paddingTop: "10px",
      textTransform: "capitalize",
    },
    email: {
      paddingTop: "10px",
    },
  };

  return (
    <>
      <div className="main">
        <Container maxWidth="sm">
          {status ? (
            <>
              <Grid container spacing={4}>
                {/* <div className="main__logo">
                  <img src={ProfileImg} alt="profile" />
                </div> */}
                <CustomParaGraph
                  // boldText="Company Name: "
                  customStyle={{
                    textTransform: "capitalize",
                    fontSize: "24px",
                    paddingLeft: "0px",
                    paddingTop: "10px",
                  }}
                  boldText={props?.data?.Company_Name || "N/A"}
                />
                <CustomParaGraph
                  // boldText="About US: "
                  customStyle={customStyle.email}
                  text={props?.data?.About_Us || "N/A"}
                />
                <CustomParaGraph
                  customStyle={customStyle.paragraph}
                  boldText="Contact Person: "
                  text={props?.data?.Contact_Person || "N/A"}
                />
                <CustomParaGraph
                  customStyle={customStyle.paragraph}
                  boldText="Designation: "
                  text={props?.data?.Designation || "N/A"}
                />
                <CustomParaGraph
                  customStyle={customStyle.email}
                  boldText="Email: "
                  text={props?.data?.Email_Id || "N/A"}
                />
                <CustomParaGraph
                  customStyle={customStyle.email}
                  boldText="Phone Number: "
                  text={props?.data?.Contact_Number || "N/A"}
                />

                <CustomParaGraph
                  customStyle={customStyle.paragraph}
                  boldText="Address: "
                  text={props?.data?.Address || "N/A"}
                />

                <CustomParaGraph
                  customStyle={customStyle.paragraph}
                  boldText="Country: "
                  text={props?.data?.Country || "N/A"}
                />
                <CustomParaGraph
                  customStyle={customStyle.paragraph}
                  boldText="Contact Platform: "
                  text={props?.data?.Platform || "N/A"}
                />

                <CustomParaGraph
                  customStyle={customStyle.paragraph}
                  boldText="Certification: "
                  text={props?.data?.Certification || "N/A"}
                />
              </Grid>
            </>
          ) : (
            <Loader />
          )}
        </Container>
      </div>
    </>
  );
};

export default CompanyDetails;
