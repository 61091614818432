import React from "react";

const WarningMessage = (props) => {
  const customStyle = {
    color: props?.type === "warning" ? "yellow" : props?.type === "error" ? "red" : props?.type === "sucess" ? "green" : "black",
  };

  return (
    <p className="warning-message" style={customStyle}>
      {props?.text}
    </p>
  );
};

export default WarningMessage;
