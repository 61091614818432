export const promotions = {
  mainDiv: {
    textAlign: "center",
    padding: "40px",
    paddingBottom: "100px",
    paddingTop: "100px",
  },
  section: {
    img: {
      height: "250px",
      marginTop: "25px",
      marginBottom: "25px",
    },
    promotionButton: {
      marginTop: "30px",
    },
  },
};
