import React, { Button, Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageHeading } from "../../components";
import CustomParaGraph from "../CustomParaGraph";
import { promotions } from "./styles";

const PromotionsBanner = ({
  heading,
  text,
  onCancel,
  onCancelClick,
  onOkayClick,
}) => {
  const navigate = useNavigate();

  const handleAskForDemo = () => {
    navigate("/contact-us");
    onOkayClick();
  };
  return (
    <div style={promotions.mainDiv} className="PromotionMaindiv">
      <div className="bgOverlay">
        <Container maxWidth="xl">
          <div style={promotions.subDiv}>
            {heading && (
              <PageHeading
                customStyle={{ fontSize: "24px" }}
                textAlign="Right"
                text={heading}
              />
            )}
            {text && (
              <CustomParaGraph
                text={text}
                customStyle={{
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "12px!important",
                }}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              style={promotions.section.promotionButton}
              size="small"
              onClick={() => handleAskForDemo()}
            >
              Ask for Demo
            </Button>
            {onCancelClick && (
              <Button
                variant="contained"
                color="error"
                style={promotions.section.promotionButton}
                size="small"
                onClick={() => onCancelClick()}
              >
                Connect Later
              </Button>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};
export default PromotionsBanner;
