import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomizedDialogs = (props) => {
  return (
    <div className="popup">
      <Dialog
        fullWidth={true}
        maxWidth={props?.width || "xs"}
        open={props.isOpen}
        onClose={props.onClose}
      >
        {props?.title && (
          <DialogTitle className="popup__titleBar">{props?.title}</DialogTitle>
        )}
        <DialogContent>
          {props.children}
          {props.onClose ? (
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 13,
                color: "#fff",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogContent>
        {/* <DialogActions>
          <Button>Close</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default CustomizedDialogs;
