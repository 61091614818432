export const customStyle = {
  about: {
    backgroundColor: "#fff",
  },
  UpperSection: {
    paddingTop: "100px",
    paddingBottom: "75px",
    Content: {
      paddingRight: "20px",
    },
    imgDiv: {
      paddingTop: "50px!important",
      img: {
        width: "100%",
        borderRadius: "10px",
        marginTop: "20px",
      },
    },
  },
  LowerSection: {
    paddingTop: "100px",
    paddingBottom: "75px",
  },
};
