import Swal from "sweetalert2";

const CustomAlert = (title, message, type, time) => {
  Swal.fire({
    title: title || "Invalid Action",
    text: message || "Something went wrong! Please try after sometime.",
    icon: type || "error",
    showConfirmButton: false,
    timer: time || 1500,
  });
};

export default CustomAlert;
