import React from "react";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <Container maxWidth="lg" className="footer__linkSection">
        <Grid
          container
          justifyContent="center"
          spacing={{ xs: 2, sm: 3, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} sm={3} md={3}>
            <div>
              <Typography
                variant="h6"
                gutterBottom
                component="h6"
                className="footer__linkHeader"
              >
                Our Pages
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/" className="footer__link">
                  Home
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/about" className="footer__link">
                  About
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/services" className="footer__link">
                  Our Services
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/contact-us" className="footer__link">
                  Contact US
                </Link>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <div>
              <Typography
                variant="h6"
                gutterBottom
                component="h6"
                className="footer__linkHeader"
              >
                Our Products
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/trade-analysis" className="footer__link">
                  Trade Analysis
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/buyer" className="footer__link">
                  Buyer Data
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/supplier" className="footer__link">
                  Supplier Data
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/contact-us" className="footer__link">
                  Request for Demo
                </Link>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <div>
              <Typography
                variant="h6"
                gutterBottom
                component="h6"
                className="footer__linkHeader"
              >
                Usefull Link
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/contact-us" className="footer__link">
                  Connect With US
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/contact-us" className="footer__link">
                  Request for Demo
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/terms-conditions" className="footer__link">
                  Terms and Conditions
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                <Link to="/privacy-policy" className="footer__link">
                  Privacy Policy
                </Link>
              </Typography>
            </div>
          </Grid>

          <Grid item xs={2} sm={3} md={3}>
            <div>
              <Typography
                variant="h6"
                gutterBottom
                component="h6"
                className="footer__linkHeader"
              >
                About Bizdiving
              </Typography>
              <Typography variant="body1" gutterBottom className="footer__link">
                BizDiving is an online platform created by Zcel India Llp, where
                one can search, create and accelerate plans and procedures based
                on import and export for their business growth.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <div className="footer__copyright">
        <Container>
          <Typography
            variant="body1"
            gutterBottom
            textAlign="center"
            className="footer__copyright__link"
          >
            Copyright © 2022 Zcel India Pvt. Ltd. All Rights Reserved
          </Typography>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
