import React, { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageHeading, CustomParaGraph } from "../../components";
import MainImg from "../../assets/images/promotions/promotionsImg1.png";
import { promotions } from "./styles";

const Promotions = (props) => {
  const navigate = useNavigate();
  let discription =
    "Connect with us to unlock a spectrum of services and reap the full benefits. Enable your business potential through our offerings and experience growth like never before. We are here to assist you on your journey to success. Reach out today and let's embark on this collaborative venture, ensuring your business thrives and prospers. Your success story starts with a simple contact. Don't miss out on the opportunities that await you – get in touch now!";
  return (
    <div style={promotions.mainDiv}>
      <Container maxWidth="lg">
        <img
          src={props?.imgPath || MainImg}
          alt="status images"
          style={promotions.section.img}
        />
        <PageHeading
          text={props?.heading || "Elevate Your Experience with our Services!"}
        />
        <CustomParaGraph
          text={props?.message || discription}
          customStyle={{ fontSize: "12px", textAlign: "center" }}
        />
        <Button
          variant="contained"
          style={promotions.section.promotionButton}
          onClick={() => navigate("/contact-us")}
        >
          Ask for demo
        </Button>
      </Container>
    </div>
  );
};
export default Promotions;
