import React, { useEffect, useState, Suspense } from "react";

import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Autocomplete, IconButton, InputAdornment, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import MultipleSelectChip from "../../components/MultipleSelectChip";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DashboardReportBox from "../../components/DashboardReportBox/DashboardReportBox";

import { chapterList, filterFields, FieldsNameUsingKey, URL } from "../../Constant/StaticData";
import WarningMessage from "../../components/WarningMessage/WarningMessage";
import { Footer, CustomizedTables, Header } from "../../components";
import API_endpoint from "../../utility/APIEndPoints";
import ApiRequest from "../../utility/APIRequest";
import { PageLoader } from "../../components";
import { CustomAlert } from "../../Utils";
import {
  formatDownlableData,
  GetPagination,
  handleSerchDataFormat,
  ValidateTradeAnalysisForm,
  ValidateTradeAnalysisFormNew,
} from "../../Utils/commonFunction";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const ImportExport = () => {
  let initialFormData = { chapter: 21, dataType: "export", selectedFieldType: "product_name", selectedField: "" };
  const [isLoading, setIsLoading] = useState({ table: false, download: false, nextLevelFilter: false });
  const [isFiltered, setIsFiltered] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [page, setPage] = useState("1");
  const [filteredData, setFilteredData] = useState();
  const [shortAnalysis, setShortAnalysis] = useState({ loader: false, data: [] });
  const [selectedFilterField, setSelectedFieldData] = useState({ tValue: "", list: [] });
  const [selectedFieldType, setSelectedFieldType] = useState("product_name");
  const [formValidation, setFormValidation] = useState("");
  const [uniqueList, setUniqueList] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    getFilterData(formData, false, true); // Paramater 1: search data, 2:is data to download 3: is first search
  };

  const handleDownloadFilterData = () => {
    if (shortAnalysis?.data?.Shipments < 20001) {
      if (filteredData?.subscription) {
        getFilterData(formData, true, true);
      } else {
        CustomAlert("Subscription not found", "You have subscription to download the data. Please connect our sales team!", "warning", 3000);
      }
    } else {
      CustomAlert(
        "Download Limit",
        "You have limit of 20,000 data to download at a time. Please try with less data or connect support!",
        "warning",
        3000
      );
    }
  };

  const getFilterData = async (formData, filterForDownload = false, isFirstTime) => {
    localStorage.setItem("filter", JSON.stringify(formData));
    let isValidation = ValidateTradeAnalysisForm(formData);
    setFormValidation(isValidation?.errorList);

    if (isValidation?.status) {
      setIsLoading({ table: !filterForDownload, download: filterForDownload });
      let APIData = await handleSerchDataFormat(formData, filterForDownload || isFirstTime ? "1" : page, filterForDownload);
      let ApiEndPoint = formData?.dataType === "import" ? API_endpoint?.import : API_endpoint?.export;

      ApiRequest.request(ApiEndPoint, "POST", APIData).then((response) => {
        if (response?.status) {
          if (filterForDownload) {
            downloadExcel(response?.result?.searchResult);
            setIsLoading({ table: false, download: false });
          } else {
            setFilteredData(response?.result);
            setIsFiltered(true);
            if (isFirstTime && response?.result?.searchResult?.length > 0) {
              getUniqueDataList(APIData, formData?.dataType);
              getShortAnalysis(APIData, formData?.dataType);
            }
            // if (response?.result?.subscription) {}
            setIsLoading({ table: false, download: false });
          }
        } else {
          CustomAlert("Invalid Action", "something went wrong!", "error", 5000);
          setIsLoading({ table: false, download: false });
        }
      });
    }
  };

  const getUniqueDataList = (apiData, type) => {
    // setUniqueList(() => setUniqueList());
    ApiRequest.request(`/analytics/${type}/unique-analysis`, "POST", apiData).then((response) => {
      if (response?.status) {
        setUniqueList(response?.result);
      }
    });
  };

  // Handling short analysis
  const getShortAnalysis = (apiData, type) => {
    setShortAnalysis({ loader: true, data: [] });

    ApiRequest.request(`/analytics/${type}/sort-analysis`, "POST", apiData).then((response) => {
      if (response?.status) {
        setShortAnalysis({ loader: false, data: response?.result });
      }
    });
  };

  const addSelectedFieldData = async () => {
    let dataValidation = await ValidateTradeAnalysisFormNew(selectedFieldType, selectedFilterField?.tValue, formData?.chapter);
    setFormValidation(dataValidation?.errorList);

    if (dataValidation?.status) {
      if (selectedFilterField?.list?.length == 5) {
        setFormValidation({ selectedField: { status: true, message: `Max 5 filter is allowed` } });
      } else if (selectedFilterField?.list?.includes(selectedFilterField?.tValue)) {
        setFormValidation({ selectedField: { status: true, message: `Same data multiple times is allowed` } });
      } else {
        let data = [...selectedFilterField?.list];
        data.push(selectedFilterField?.tValue);
        setSelectedFieldData((selectedFilterField) => ({ ...selectedFilterField, tValue: "", list: data }));
        handleFormdata({ value: data, name: "selectedField" });
      }
    }
  };

  const removeSelectedFieldData = (value) => {
    let oldData = [...selectedFilterField?.list];
    let newData = oldData?.filter((item) => item !== value);
    setSelectedFieldData((selectedFilterField) => ({ ...selectedFilterField, list: newData }));
    handleFormdata({ value: newData, name: "selectedField" });
  };

  const handleFormdata = (data) => {
    const { name, value } = data;
    setFormData({ ...formData, [name]: value });
  };

  const handleFieldTypeSelection = (data) => {
    setSelectedFieldData({ tValue: "", list: [] });
    setSelectedFieldType(data.value);
    setFormData({ ...formData, selectedFieldType: data.value, selectedField: [] });
  };

  const handleSecondLayer = (newData, existingData, name) => {
    let data = existingData?.length > 0 ? [...existingData] : [];
    data.push(newData);
    handleFormdata({ name: name, value: data });
  };

  // download the excel of data
  const downloadExcel = (sourceData) => {
    let data = formatDownlableData(sourceData);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "DataSheet.xlsx");
    setIsLoading({ table: false, download: false });
    CustomAlert("Thankyou for downloading!", "Your Data is downloaded sucessfully.", "success", 3000);
  };

  const handleCountryWiseAnalysis = async () => {
    let data = await handleSerchDataFormat(formData, "1", true);
    localStorage.setItem("formDataForCountryWiseAnalysis", JSON.stringify(data));
    window.open(`${URL?.BASE_URL}shipment-wise-analysis`, "_blank");
  };

  const handleValueWiseAnalysis = async () => {
    let data = await handleSerchDataFormat(formData, "1", true);
    localStorage.setItem("formDataForCountryWiseAnalysis", JSON.stringify(data));
    window.open(`${URL?.BASE_URL}value-wise-analysis`, "_blank");
  };

  useEffect(() => {
    filteredData && getFilterData(formData, false, false); // Next page data will show on page change
  }, [page]);

  return (
    <div className="import_export">
      <PageLoader open={isLoading?.table || isLoading?.download} message="We are processing the data. Please wait..." />
      <Header />

      <Container maxWidth="xl" className="import_export_filter">
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <Grid item xs={4}> */}
              <FormControl variant="standard" sx={{ m: 1, minWidth: "100px" }}>
                <TextField
                  id="outlined-select-currency"
                  name="chapter"
                  size="small"
                  select
                  label="Chapter"
                  defaultValue="21"
                  onChange={(e) => handleFormdata(e.target)}
                  className="f_level"
                >
                  {chapterList?.map((item) => {
                    return (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1 }}>
                <TextField
                  id="outlined-select-currency"
                  name="dataType"
                  size="small"
                  select
                  label="Type"
                  defaultValue="export"
                  onChange={(e) => handleFormdata(e.target)}
                  className="f_level"
                >
                  <MenuItem value="export">Export Data</MenuItem>
                  <MenuItem value="import">Import Data</MenuItem>
                </TextField>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: "200px" }}>
                <TextField
                  id="outlined-select-currency"
                  size="small"
                  select
                  label="Select Field"
                  defaultValue="product_name"
                  style={{ height: "none!important", fontSize: "12px!important" }}
                  onChange={(e) => handleFieldTypeSelection(e.target)}
                  className="f_level"
                >
                  {filterFields?.map((item) => {
                    return (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
              {/* </Grid>
            <Grid item xs={3}> */}
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined" className="f_level">
                <InputLabel htmlFor="outlined-adornment-password">{FieldsNameUsingKey[selectedFieldType]}</InputLabel>
                <OutlinedInput
                  id="selectFieldType"
                  type="text"
                  name="selectFieldType"
                  label={FieldsNameUsingKey[selectedFieldType]}
                  value={selectedFilterField?.tValue}
                  onChange={(e) => setSelectedFieldData({ ...selectedFilterField, tValue: e.target.value })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={(e) => onTypeSelectedFilterField(e.target.value)}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        <AddCircleIcon color="primary" onClick={() => addSelectedFieldData()} />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formValidation?.selectedField?.status && <WarningMessage type="error" text={formValidation?.selectedField?.message} />}

                <Stack spacing={2}>
                  {selectedFilterField?.list?.map((item) => {
                    return (
                      <Tooltip key={item} title={item}>
                        <Chip
                          key={item}
                          label={item}
                          onDelete={() => removeSelectedFieldData(item)}
                          style={{ width: "100%", justifyContent: "space-between", marginTop: "5px" }}
                        />
                      </Tooltip>
                    );
                  })}
                </Stack>
              </FormControl>
              {/* </Grid>
            <Grid item xs={3}> */}
              <FormControl sx={{ m: 1, minWidth: "10%" }} variant="standard">
                <TextField
                  // label="Supplier"
                  variant="outlined"
                  size="small"
                  id="toDate"
                  type="date"
                  name="start_date"
                  onChange={(e) => handleFormdata(e.target)}
                />
                {formValidation?.start_date?.status && <WarningMessage type="error" text={formValidation?.start_date?.message} />}
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: "10%" }} variant="standard">
                <TextField
                  // label="Supplier"
                  variant="outlined"
                  size="small"
                  id="toDate"
                  type="date"
                  name="end_date"
                  onChange={(e) => handleFormdata(e.target)}
                />
                {formValidation?.end_date?.status && <WarningMessage type="error" text={formValidation?.end_date?.message} />}
              </FormControl>
              {/* </Grid>
            <Grid item xs={1}> */}
              <FormControl sx={{ m: 1 }} variant="standard">
                <Stack direction="row" spacing={2}>
                  <IconButton
                    aria-label="delete"
                    variant="contained"
                    type="submit"
                    size="small"
                    style={{ background: "#3276d2", borderRadius: "3px", color: "#fff" }}
                    onClick={(e) => onSubmit(e)}
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    variant="contained"
                    size="small"
                    style={{ background: "red", borderRadius: "3px", color: "#fff" }}
                    onClick={() => window.location.reload()}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <br />

          <Grid container spacing={1} className="second_level">
            {uniqueList?.importers?.length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <MultipleSelectChip
                    id="importer_list"
                    label="Importer"
                    data={uniqueList?.importers}
                    onChangeCallback={(data) => {
                      handleFormdata({ name: "importer_name", value: data });
                    }}
                  />
                  {/* <Autocomplete
                    multiple
                    limitTags={2}
                    id="importer_list"
                    options={uniqueList?.importers}
                    getOptionLabel={(option) => option.data}
                    renderInput={(params) => <TextField {...params} label="Importer" placeholder="Search" />}
                    onChange={(data) => {
                      handleSecondLayer(data?.target?.innerText, formData?.importer_name, "importer_name");
                    }}
                  /> */}
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelectChip
                    id="exporter_list"
                    label="Exporter"
                    data={uniqueList?.exporters}
                    onChangeCallback={(data) => {
                      handleFormdata({ name: "exporter_name", value: data });
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MultipleSelectChip
                    id="country_list"
                    label="Country"
                    data={uniqueList?.countries}
                    onChangeCallback={(data) => {
                      handleFormdata({ name: "country", value: data });
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
        <br />
        <br />
        <br />
        {/* <hr /> */}

        <div className="import_export_filter__tableCard__data">
          {filteredData?.searchResult && shortAnalysis?.data?.Shipments && (
            <>
              <DashboardReportBox data={shortAnalysis?.data} />

              <br />
              <br />
              <br />

              <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                <Button
                  startIcon={<SignalCellularAltIcon />}
                  onClick={() => handleCountryWiseAnalysis()}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ fontWeight: "600" }}
                >
                  Shipment Wise Analysis
                </Button>
                <Button
                  startIcon={<SignalCellularAltIcon />}
                  onClick={() => handleValueWiseAnalysis()}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ fontWeight: "600" }}
                >
                  Value Wise Analysis
                </Button>
                <Button
                  startIcon={<DownloadForOfflineIcon />}
                  onClick={() => handleDownloadFilterData()}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ fontWeight: "600" }}
                >
                  Download
                </Button>
              </Stack>
              <br />
            </>
          )}

          {filteredData?.searchResult && (
            <CustomizedTables tableData={filteredData?.searchResult} isSubscription={false} filterType={formData?.dataType} />
          )}
          <div className="import_export_filter__tableCard__data__pagination">
            {shortAnalysis?.data?.Shipments && (
              <Pagination
                count={GetPagination(shortAnalysis?.data?.Shipments)}
                onChange={(event, value) => setPage(value)}
                showFirstButton
                showLastButton
                color="primary"
              />
            )}
          </div>
        </div>
      </Container>
      {!filteredData?.searchResult && <div style={{ height: "80vh" }}></div>}

      <Footer />
    </div>
  );
};
export default ImportExport;
