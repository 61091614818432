import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { AgCharts } from "ag-charts-react";

const BarChart = (props) => {
  const [options, setOptions] = useState({
    // Data: Data to be displayed in the chart
    data: [],
    // Series: Defines which chart type and data to use
    series: [
      {
        type: "bar",
        xKey: "data",
        yKey: "count",
      },
    ],
  });

  useEffect(() => {
    setOptions({ ...options, data: props?.data });
  }, [props?.data]);

  return <AgCharts options={options} />;
};

export default BarChart;
