import React, { useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Pagination from "@mui/material/Pagination";
import PublicIcon from "@mui/icons-material/Public";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import BusinessIcon from "@mui/icons-material/Business";

import { ActionCard, ComponentLoader, PageLoader } from "../../components";
import { Footer, CustomizedTables, Header } from "../../components";
import { GetPagination, handleSerchDataFormat } from "../../Utils/commonFunction";
import { CustomAlert } from "../../Utils";
import ApiRequest from "../../utility/APIRequest";
import Promotions from "../../components/Promotions/Promotions";
import API_endpoint from "../../utility/APIEndPoints";
import DashboardReportBox from "../../components/DashboardReportBox/DashboardReportBox";

const FullDataTable = () => {
  const isFirstTime = useRef(true);
  const [isFiltered, setIsFiltered] = useState(false);
  const [formData, setFormData] = useState();
  const [page, setPage] = useState("1");
  const [filteredData, setFilteredData] = useState();
  const [shortAnalysis, setShortAnalysis] = useState({ loader: false, data: [] });

  const getFilterData = async (data) => {
    setIsFiltered(false);
    let APIData = await handleSerchDataFormat(data, page, false);
    let ApiEndPoint = data?.dataType === "import" ? API_endpoint?.import : API_endpoint?.export;

    ApiRequest.request(ApiEndPoint, "POST", APIData).then((response) => {
      if (response?.status) {
        setFilteredData(response?.result);
        setIsFiltered(true);
        if (response?.result?.subscription && page === "1") {
          isFirstTime.current = false;
          getShortAnalysis(APIData, data?.dataType);
        }
      } else {
        CustomAlert("Invalid Action", "something went wrong!", "error", 5000);
        setIsFiltered(true);
      }
    });
  };

  // Handling short analysis
  const getShortAnalysis = (apiData, type) => {
    setShortAnalysis({ loader: true, data: [] });

    ApiRequest.request(`/analytics/${type}/sort-analysis`, "POST", apiData).then((response) => {
      if (response?.status) {
        setShortAnalysis({ loader: false, data: response?.result });
      }
    });
  };

  const handleDataUpdate = () => {
    if (isFirstTime?.current) {
      let localStorageData = localStorage.getItem("filter");
      let data = JSON.parse(localStorageData); // filter obj got from local storage
      setFormData(data);
      getFilterData(data);
    } else if (page !== "1") {
      getFilterData(formData);
    }
  };

  useEffect(() => {
    handleDataUpdate();

    return () => {
      isFirstTime.current = false;
    };
  }, [page]);

  return (
    <div className="import_export">
      <PageLoader open={!isFiltered} message="We are processing the data. Please wait..." />
      <Header />

      <Container maxWidth="xl" className="import_export_filter">
        {filteredData?.subscription ? (
          <>
            <div className="import_export_filter__tableCard__data">
              <DashboardReportBox data={shortAnalysis?.data} />

              <br />

              {filteredData?.searchResult?.length > 0 && (
                <CustomizedTables
                  tableData={filteredData?.searchResult}
                  isSubscription={filteredData?.subscription}
                  filterType={formData?.dataType}
                />
              )}
              <div className="import_export_filter__tableCard__data__pagination">
                {shortAnalysis?.data?.Shipments && (
                  <Pagination
                    count={GetPagination(shortAnalysis?.data?.Shipments)}
                    onChange={(event, value) => setPage(value)}
                    showFirstButton
                    showLastButton
                    color="primary"
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          <Promotions
            heading="You don't have subscription!"
            message="You don't have subscription for see full data. Please connect support to get the subscription or request for demo."
          />
        )}
      </Container>

      <Footer />
    </div>
  );
};
export default FullDataTable;
