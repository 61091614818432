import React, { useEffect, useRef, useState } from "react";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { Button, Stack, Typography } from "@mui/material";

import { Footer, Header, PageLoader, Promotions, Status } from "../../../components";
import ApiRequest from "../../../utility/APIRequest";
import API_endpoint from "../../../utility/APIEndPoints";
import { CustomAlert } from "../../../Utils";
import BarChart from "../../../components/Barchart";
import { formatDataForGraph, handleSerchDataFormat, ValidateTradeAnalysisForm } from "../../../Utils/commonFunction";
import CustomList from "../../../components/CustomList/CustomList";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import ExcelImg from "../../../assets/images/icons/excelImg.png";
import PdfImg from "../../../assets/images/icons/pdf.png";
import DownloadForOfflineSharpIcon from "@mui/icons-material/DownloadForOfflineSharp";

const ShipmentWiseAnalysis = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [analysisData, setAnalysisData] = useState();
  const isMounted = useRef(true);

  const getFilterData = async (formData) => {
    let APIData = await handleSerchDataFormat(formData, "1");

    ApiRequest.request(`/analytics/${formData?.dataType}/detail-analysis`, "POST", APIData).then((response) => {
      if (response?.status) {
        setAnalysisData(response?.result);
        setIsLoading(false);
      } else {
        setAnalysisData(null);
        setIsLoading(false);
      }
    });
  };

  // download the excel of data
  const downloadExcel = () => {
    let data = analysisData;
    const countries = XLSX.utils.json_to_sheet(data?.countries);
    const importers = XLSX.utils.json_to_sheet(data?.importers);
    const exporters = XLSX.utils.json_to_sheet(data?.exporters);
    const port_of_loading = XLSX.utils.json_to_sheet(data?.port_of_loading);
    const port_of_discharge = XLSX.utils.json_to_sheet(data?.port_of_discharge);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, countries, "Country");
    XLSX.utils.book_append_sheet(workbook, importers, "Importer");
    XLSX.utils.book_append_sheet(workbook, exporters, "Exporter");
    XLSX.utils.book_append_sheet(workbook, port_of_loading, "Port Of Loading");
    XLSX.utils.book_append_sheet(workbook, port_of_discharge, "Port of Discharge");

    XLSX.writeFile(workbook, "shipment_wise_analysis.xlsx");
    // setIsDownloading(false);
    CustomAlert("Thankyou for downloading!", "Your Data is downloaded sucessfully.", "success", 3000);
  };

  const generatePDF = () => {
    const content = document.getElementById("analysis");

    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // Width of A4 page in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("shipment_wise_analysis.pdf");
    });
  };

  useEffect(() => {
    if (isMounted?.current) {
      let localStorageData = localStorage.getItem("filter");
      let data = JSON.parse(localStorageData);
      if (data) {
        getFilterData(data);
      } else {
        setIsLoading(false);
      }
    }
    return () => (isMounted.current = false);
  }, []);

  return (
    <div>
      <Header />

      <Container maxWidth="xl" className="import_export_filter">
        {isLoading ? (
          <>
            <PageLoader open={isLoading} />
            <div style={{ height: "70vh" }}></div>
          </>
        ) : analysisData?.countries ? (
          <Grid container spacing={2} className="import_export_filter__graphChart">
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                <img src={PdfImg} alt="Download" style={{ width: "25px", cursor: "pointer" }} onClick={() => generatePDF()} />
                <img src={ExcelImg} alt="Download" style={{ width: "25px", cursor: "pointer" }} onClick={() => downloadExcel()} />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <div id="analysis">
                  <BarChart data={formatDataForGraph(analysisData?.countries)} />
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{
                      padding: "5px",
                      textAlign: "center",
                      backgroundColor: "#4a4a4a",
                      color: "#fff",
                    }}
                  >
                    Top 10 countries by no. of shipment
                  </Typography>
                </div>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <CustomList leftTitle="Country" rightTitle="No of Shipments" data={analysisData?.countries} />
            </Grid>
            <Grid item xs={6}>
              <CustomList leftTitle="Exporter" rightTitle="No of Shipments" data={analysisData?.exporters} />
            </Grid>
            <Grid item xs={6}>
              <CustomList leftTitle="Importer" rightTitle="No of Shipments" data={analysisData?.importers} />
            </Grid>
            <Grid item xs={6}>
              <CustomList leftTitle="Port of Loading" rightTitle="No of Shipments" data={analysisData?.port_of_loading} />
            </Grid>
            <Grid item xs={6}>
              <CustomList leftTitle="Port of discharge" rightTitle="No of Shipments" data={analysisData?.port_of_discharge} />
            </Grid>
          </Grid>
        ) : (
          <>
            <Promotions
              heading="You don't have subscription!"
              message="You don't have subscription for see full data. Please connect support to get the subscription or request for demo."
            />
          </>
        )}
      </Container>

      <Footer />
    </div>
  );
};
export default ShipmentWiseAnalysis;
