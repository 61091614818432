import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
} from "@mui/material";
import {
  CustomParaGraph,
  Footer,
  Header,
  PageLoader,
  SubHeading,
} from "../../components";
import ProfileImg from "../../assets/images/Profile/profile.png";
import WarningMessage from "../../components/WarningMessage/WarningMessage";
import { useForm } from "react-hook-form";
import { RESET_PASSWORD } from "../../Constant/StaticData";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ApiRequest from "../../utility/APIRequest";
import API_endpoint from "../../utility/APIEndPoints";
import { CustomAlert } from "../../Utils";

const Reset = (props) => {
  const [isError, setError] = useState({});
  const [inProcess, setInProcess] = useState(true);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    handleFormData(data);
  };

  const handleFormData = (formData) => {
    if (formData?.password === formData?.confirm_password) {
      let path = window.location.hash;
      let token = path.replace("#/reset/", "");
      let APIData = formData;
      ApiRequest.request(
        `${API_endpoint?.resetPassword}/${token}`,
        "PATCH",
        APIData
      ).then((res) => {
        if (res?.status) {
          CustomAlert(
            "Successfully Updated",
            "Your password is updated successfully!",
            "success",
            5000
          );
          navigate("/");
        } else {
          CustomAlert(
            "Invalid URL",
            "something went wrong please connect support!",
            "warning",
            5000
          );
        }
      });
    } else {
      setError({
        status: true,
        message: "Your Password & Conform password doesn't match.",
      });
    }
  };

  return (
    <>
      <Header />

      <div className="profile">
        <Grid container spacing={2} style={{ padding: "18px" }}>
          <SubHeading
            color="#337ab7"
            style={{ textAlign: "center", with: "100%" }}
            text="Reset your password here!"
          />

          <CustomParaGraph text="To reset your password, please enter your new password and confirm it below. Your password must at least 8 characters long, Contains a mix of uppercase and lowercase letters & Includes at least one digit or special character." />
          <br />
          <CustomParaGraph text="Once you’ve entered your new password, click the “Reset Password” button to complete the process. If you encounter any issues, please contact our support team." />
        </Grid>
        <Container maxWidth="lg">
          <Card>
            <div className="profile__card">
              <div className="profile__card__rightSide">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid
                    container
                    spacing={1}
                    className="import-export__filter__filterCard__form__mainGrid"
                  >
                    <Grid item xs={6}>
                      <FormControl
                        sx={{ m: 1, minWidth: "80%" }}
                        variant="standard"
                      >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                          id="password"
                          type="password"
                          {...register("password", {
                            min: 2,
                            required: true,
                          })}
                        />
                      </FormControl>
                      {errors?.password?.type === "required" && (
                        <WarningMessage
                          type="error"
                          text={RESET_PASSWORD?.password}
                        />
                      )}
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        sx={{ m: 1, minWidth: "80%" }}
                        variant="standard"
                      >
                        <InputLabel htmlFor="confirm_password">
                          Confirm Password
                        </InputLabel>
                        <Input
                          id="confirm_password"
                          type="password"
                          {...register("confirm_password", {
                            min: 2,
                            required: true,
                          })}
                        />
                      </FormControl>
                      {errors?.confirm_password?.type === "required" && (
                        <WarningMessage
                          type="error"
                          text={RESET_PASSWORD?.confirm_password}
                        />
                      )}
                    </Grid>
                    {isError?.status && (
                      <WarningMessage type="error" text={isError?.message} />
                    )}
                    <Grid
                      item
                      xs={12}
                      className="contactus__content__formSection__submit"
                    >
                      <Button variant="contained" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </Card>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Reset;
