import React from "react";
import { Container } from "@mui/material";
import {
  Footer,
  PromotionsBanner,
  Header,
  PageHeading,
  CustomParaGraph,
  SubHeading,
} from "../../components";

const TermsConditions = (props) => {
  return (
    <div className="privacy-policy">
      <Header />
      <Container maxWidth="lg" className="privacy_policy__content">
        <PageHeading text="Our Terms & Conditions" />
        <br />
        <br />

        <SubHeading text="Acceptance of Terms" />
        <CustomParaGraph text="By accessing and using our website (www.bizdiving.com), you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use the Site." />
        <br />
        <SubHeading text="Data Use and Ownership" />
        <CustomParaGraph text="a. Data Purchase: Upon payment, you acquire a limited, non-exclusive, and non-transferable license to use the purchased import and export data for lawful business purposes." />
        <CustomParaGraph text="b. Ownership: All data remains the intellectual property of Zcel India Pvt. Ltd. You may not resell, sublicense, redistribute, or otherwise transfer the data to any third party without our explicit written consent." />
        <br />
        <SubHeading text="User Responsibilities" />
        <CustomParaGraph text="a. Accurate Information: You agree to provide accurate and complete information when purchasing data." />
        <CustomParaGraph text="b. Authorized Access: Users must be authorized employees or agents of the purchasing entity." />
        <br />
        <SubHeading text="Payment and Pricing" />
        <CustomParaGraph text="a. Payment: All payments are due upon purchase. We use secure payment methods to protect your financial information." />
        <CustomParaGraph text="b. Pricing Changes: Zcel India Pvt. Ltd. reserves the right to change pricing at any time, with changes effective upon posting on the Site." />

        <br />
        <SubHeading text="Refund Policy" />
        <CustomParaGraph text="Due to the nature of the data being digital and non-returnable, refunds are generally not provided. However, exceptions may be made in certain circumstances, and such requests should be directed to support@bizdiving.com" />
        <br />
        <SubHeading text="Confidentiality" />
        <CustomParaGraph text="a. Data Confidentiality: You agree to treat all data purchased as confidential and not disclose it to unauthorized parties." />
        <CustomParaGraph text="b. User Accounts: Keep login credentials confidential, and notify us immediately of any unauthorized use." />
        <br />
        <SubHeading text="Limitation of Liability" />
        <CustomParaGraph text="a. Data Accuracy: While we strive for accuracy, we do not guarantee the completeness, accuracy, or reliability of the data. Use it at your own risk." />
        <CustomParaGraph text="b. Indirect Damages: In no event shall Zcel India Pvt. Ltd. be liable for any indirect, consequential, special, or punitive damages." />
        <br />
        <SubHeading text="Governing Law" />
        <CustomParaGraph text="These Terms and Conditions shall be governed by and construed in accordance with the laws of [Your Jurisdiction]." />
        <br />
        <SubHeading text="Changes to Terms" />
        <CustomParaGraph text="We reserve the right to modify these Terms and Conditions at any time. Changes will be effective upon posting on the Site." />
        <br />
        <SubHeading text="Contact Information" />
        <CustomParaGraph text="If you have any questions or concerns about these Terms and Conditions, please contact us at support@bizdiving.com" />
        <CustomParaGraph text="By using our website, you acknowledge that you have read, understood, and agree to these Terms and Conditions." />
        <br />
      </Container>
      <PromotionsBanner
        heading="Schedule a Demo!"
        text="Please connect with us on +91-9821342553 or info@zcelindia.com to get or renew your subscription. You can also click on the button to message our support and will call you shortly."
      />
      <Footer />
    </div>
  );
};

export default TermsConditions;
