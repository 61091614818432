import React from "react";
import bannerImg from "../../assets/images/About/about_us-banner.png";
import {
  Testimonial,
  PromotionsBanner,
  Footer,
  Header,
  Plan,
  About,
} from "../../components";

const AboutUS = () => {
  return (
    <div className="about_us">
      <Header />
      <img
        src={bannerImg}
        className="about_us__banner"
        alt="Contact us banner"
      />
      <About />
      <PromotionsBanner
        heading="Schedule a Demo!"
        text="Please connect with us on +91-9821342553 or info@zcelindia.com to get or renew your subscription. You can also click on the button to message our support and will call you shortly."
      />
      <Plan />
      <Testimonial />
      <Footer />
    </div>
  );
};
export default AboutUS;
