import { Container, Grid } from "@mui/material";
import React from "react";
import { PageHeading, CustomParaGraph } from "../../components";
import CancelImg from "../../assets/images/status-cancel.svg";

const Status = (props) => {
  return (
    <div className="status">
      <Container maxWidth="md" className="status__section">
        <img
          src={props?.imgPath || CancelImg}
          alt="status images"
          className="status__section__img"
        />
        <PageHeading text={props?.heading || "Oh! Please try again"} />
        <CustomParaGraph
          text={
            props?.message ||
            "Something went wrong. please try again or contact support with the help of contact page!"
          }
          customStyle={{ fontSize: "12px", textAlign: "center" }}
        />
        {props.children}
      </Container>
    </div>
  );
};
export default Status;
